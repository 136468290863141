.swal-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.swal-button {
    margin: 5px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.swal-button:hover {
    background-color: #45a049;
}

.swal-default {
    background-color: #2196F3;
}

.swal-default:hover {
    background-color: #1e87dd;
}

/* .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #2778c4;
    color: #fff;
    font-size: 1.0625em;
    display: none!important;
} */
